<template>
  <div>
    <b-container fluid class="p-1">
      <b-row>
        <b-col>
          <b-img-lazy
            thumbnail
            fluid
            src="../../assets/images/logo/13333.jpg"
            alt="Image 2')"
          />
        </b-col>
      </b-row>
      <br />
      <div style="text-align: center">
        <div v-if="ck == false">该订单已经提交过申请!</div>
        <b-button
          variant="gradient-primary"
          :disabled="isSubmitting"
          v-if="ck"
          @click="paycash()"
        >
          提现
        </b-button>
      </div>
    </b-container>
  </div>
</template>
<script>
import {
  BImg,
  BBadge,
  BContainer,
  BRow,
  BCol,
  BImgLazy,
  BAvatar,
  BButton,
} from "bootstrap-vue";

import useJwt from "@/auth/jwt/useJwt";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    BBadge,
    BImg,
    BContainer,
    BRow,
    BCol,
    BImgLazy,
    BAvatar,
    BButton,
  },
  data() {
    return {
      code: "",
      cid: "",
      fid: "",
      isSubmitting: false,
      ck: true,
    };
  },
  methods: {
    paycash() {
      useJwt
        .paycash({
          cid: this.cid,
          fid: this.fid,
          code: this.code,
          oid: this.oid,
        })
        .then((res) => {
          console.log("res", JSON.stringify(res));
          if (res.data.code == "0") {
            this.isSubmitting = true;
            this.$toast({
              component: ToastificationContent,
              position: "bottom-center",
              props: {
                title: `${res.data.data}`,
                icon: "CoffeeIcon",
                variant: "success",
                text: `提交成功,请等待打款`,
              },
            });

            setTimeout(() => {
              WeixinJSBridge.call("closeWindow");
            }, 3000);
          }
          if (res.data.code == "-1") {
            this.$toast({
              component: ToastificationContent,
              position: "bottom-center",
              props: {
                title: `提现失败!`,
                icon: "CoffeeIcon",
                variant: "success",
                text: `${res.data.msg}`,
              },
            });
          }
        });
    },
  },
  mounted() {
    document.title = "提现";
    this.code = this.$route.query.code;
    this.fid = this.$route.query.fid; //推广者微信
    this.cid = this.$route.query.cid;
    this.oid = this.$route.query.oid;
    useJwt.querypayOrder({ cid: this.cid, oid: this.oid }).then((res) => {
      console.log("res", res);
      if (res.data.data == "1") {
        this.ck = false;
        setTimeout(() => {
          WeixinJSBridge.call("closeWindow");
        }, 5000);
      }
    });
  },
};
</script>